.about-home {
	margin: 125px auto 40px;
	max-width: 800px;

	@media (max-width: $small-screen) {
		width: auto;
		margin: 125px 20px 20px;
	}

	&__show-desktop {
		display: block;
		@media (max-width: $small-screen) {
			display: none;
		}
	}

	&__show-mobile {
		display: none;
		@media (max-width: $small-screen) {
			display: block;
		}
	}

	&__col-2 {
		width: 50%;
	}

	&__img {
		padding-right: 10px;

		@media (max-width: $small-screen) {
			padding-right: 0;
		}
	}

	&__section {
		margin: 30px 0;
	}

	&__cv-items {
		display: flex;
		flex-direction: column;
	}

	&__short-p {
		max-width: 650px;
		text-align: justify;
	}
}
