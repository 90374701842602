@font-face {
  font-family: 'ak-reg';
  font-style: normal;
  font-weight: 400;
  src: local('AkzidenzGrotesk-Regular'), url(/assets/AkzidenzGrotesk-Regular.woff) format('woff');
}

@import 'node_modules/normalize.css/normalize';
@import 'node_modules/slick-carousel/slick/slick';
@import 'node_modules/slick-carousel/slick/slick-theme';

@import 'variables';
@import 'utils';
@import 'base';

@import 'components/menu';
@import 'components/work';
@import 'components/slider';
@import 'components/convert-kit-mills-form';
@import 'components/course';
@import 'components/about';
@import 'components/shop';
@import 'components/project';
