// colors
$black: #000;
$white: #FFF;
$brand-color: #2B3636; // #7A5D74;
$course-btn-color: $brand-color;
$second-brand-color: #eeb3bc;

// fonts
$font-family: 'ak-reg', 'Raleway','Helvetica Neue',Helvetica,Arial,sans-serif;

// screen queries
$small-screen: 768px;
$medium-screen: 992px;
$large-screen: 1200px;
