.course {

    &__banner {
        height: 560px;
        width: 100%;
		background-color: $brand-color;
		//background: url(/assets/work-02.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 30% 65%;
    }

    &__title-wrapper {
        margin: 113px 50px 50px;
        padding-top: 70px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: $small-screen) {
            padding-top: 100px;
        }
    }

    &__title {
        width: 400px;

        @media (max-width: $small-screen) {
            width: 300px;
        }
    }

    &__workbook-wrapper {
        margin: 50px 0;

        li {
            padding: 10px
        }

        ul {
            list-style: none;
            padding: 0 40px;
        }
    }

    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
    }

    &__creator-p {
        margin: 0 16px 16px 0;
    }

    &__creator-img {
        padding-bottom: 20px;
    }

    &__creator-section {
        @media (max-width: $medium-screen) {
            flex-direction: column-reverse;
        }
    }

    &__workbooks {
        margin: 60px 0;
    }

    &__section {
        margin: 50px 0 0;

        .u-col-4 {
            padding: 5px 10px;
        }
    }

    &__button {
        border: 1px solid $course-btn-color;
        color: $course-btn-color;
        padding: 15px 40px;
        cursor: pointer;
        text-decoration: none;
        border-radius: 2px;
        display: inline-block;

        &:hover {
            background-color: $course-btn-color;
            color: white;
        }

        &:disabled {
            cursor: not-allowed;
            border-color: rgb(138, 133, 128);
            color: rgb(138, 133, 128);
            background-color: lighten(rgb(138, 133, 128), 50%);
        }
    }

    &__join {
        margin: 30px auto;
        width: 550px;
        padding: 20px 20px 30px;
        // box-shadow: 0 4px 20px 0 rgb(0 0 0 / 8%);

        @media (max-width: $small-screen) {
            width: auto;
            margin: 30px 20px;
        }
    }
}
