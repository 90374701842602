.project {
	margin: 125px 40px 40px;
	max-width: 1000px;

	@media (max-width: $small-screen) {
		width: auto;
		margin: 125px 20px 20px;
	}

  &__info {
    position: fixed;

    @media (max-width: $small-screen) {
      position: initial;
    }
  }

  &__description {
    font-size: 12px;
    display: flex;
    margin-bottom: 30px;
  }

  &__list {
    list-style: none;
    padding: 0;
  }

  &__list + &__list {
    padding-left: 45px;
  }

  &__content {
    display: flex;
    justify-content: flex-end;

    @media (max-width: $small-screen) {
      justify-content: center;
    }
  }

  &__picture-list {
    max-width: 500px;
  }

  &__img {
    margin-bottom: 60px;
  }

  &__video-content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
  }

  &__video-wrapper {
    width: 560px;
    height: 315px;

    @media (max-width: $small-screen) {
      width: 100%;
    }
  }

  &__reel-wrapper {
    width: 315px;
    height: 560px;

    @media (max-width: $small-screen) {
      width: 100%;
    }
  }
}
