// overrides
.convert-kit-mills {
    margin: 0 auto;
    border-radius: 0 !important;

    .formkit-field {
        margin-right: 0 !important;
    }

    input {
        border-radius: 0 !important;
    }

    button {
        border-radius: 0 !important;
        // border: 1px solid $black !important;
        border: none !important;
        background-color: $course-btn-color !important;
        color: $white !important;

        &:hover {
            background-color: $course-btn-color !important;
            // color: $black !important;
        }
    }

    .formkit-powered-by-convertkit {
        display: none !important;
    }
}